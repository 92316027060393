import React from "react";
import HelpPage from "../../components/helpPage";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import { graphql } from "gatsby";
import Img from "gatsby-image";

export const query = graphql`
  query {
    zdjecie1: file(
      relativePath: { eq: "Prosty-system-CRM-profil-pracownika.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(
      relativePath: { eq: "Prosty-system-CRM-edycja-profilu-pracownika.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie3: file(
      relativePath: {
        eq: "System-CRM-profil-uzytkownika-tworzenie-atrybutu.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(
      relativePath: { eq: "System-CRM-profil-uzytkownika-wlasne-atrybuty.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie5: file(
      relativePath: {
        eq: "Program-CRM-profil-uzytkownika-tworzenie-atrybutow.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie6: file(
      relativePath: {
        eq: "Program-CRM-profil-pracownika-tworzenie-wlasnych-atrybutow.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const ProfilPracownika = ({ data }) => {
  return (
    <HelpPage
      name="Ustawienia profilu pracownika"
      metaTitle="Pomoc - profil pracownika w prostym systemie CRM - QuickCRM"
      metaDescription="Profil pracownika w systemie CRM - pomoc • Jak skonfigurować profil pracownika? Jak stworzyć atrybuty opisujące profil? • Wypróbuj QuickCRM i sprzedawaj więcej, pierwsze 30 dni bezpłatnie!"
    >
      <div>
        <p>
          <strong>Lista aktywności</strong> to miejsce, w którym wyświetlane są
          zadania i zdarzenia wszystkich pracowników.
        </p>
        <p>
          Jeśli chcesz wyświetlić zadania konkretnej osoby, skorzystaj z
          zaawansowanej wyszukiwarki. Pamiętaj, że filtry możesz ze sobą łączyć
          i np. wyświetlić wyłącznie swoje zadania o konkretnym typie, np.
          planowane spotkania.
        </p>
      </div>
      <Tabs defaultTab="zakladka1">
        <TabList>
          <Tab tabFor="zakladka1">[1] Ustawienia profilu</Tab>
          <Tab tabFor="zakladka2">[2] Atrybuty pracownika</Tab>
        </TabList>
        <TabPanel tabId="zakladka1">
          <p>
            <strong>Edycja profilu pracownika</strong>
          </p>
          <p>
            Aby przejść do edycji profilu użytkownika, kliknij ikonę profilu w
            prawym górnym rogu.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Profil pracownika"
            fluid={data.zdjecie1.childImageSharp.fluid}
          />

          <p>
            [1] Możesz w łatwy sposób wskazać przełożonego pracownika.
            Wystarczy, że wybierzesz odpowiednią osobę z rozwijanej listy
            pracowników.
          </p>
          <p>
            [2] Możesz też wskazać podwładnych pracownika. Działa to w ten sam
            sposób, co dodawanie przełożonego.
          </p>
          <p>
            [3] Aby edytować bardziej szczegółowe dane dotyczące konta
            pracownika, kliknij przycisk “edycja”.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="edycja profilu pracownika"
            fluid={data.zdjecie2.childImageSharp.fluid}
          />
          <p>[4] W tym miejscu możesz edytować adres e-mail pracownika.</p>
          <p>
            [5] Możesz także w łatwy sposób wybrać kolor, który będzie kolorem
            zadań i zdarzeń pracownika wyświetlanych w kalendarzu. Ta możliwość
            znacznie zwiększe przejrzystość kalendarza i usprawnia koordynację
            pracy.
          </p>
        </TabPanel>
        <TabPanel tabId="zakladka2">
          <p>
            <strong>Tworzenie atrybutów pracowników</strong>
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="tworzenie atrybutów pracowników"
            fluid={data.zdjecie3.childImageSharp.fluid}
          />
          <p>
            [1] Aby stworzyć nowe atrybuty dla pracowników, wybierz
            “Ustawienia”, a następnie “Parametry pracowników”.
          </p>
          <p>[2] Następnie wybierz “Atrybuty pracowników”.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="własne atrybuty"
            fluid={data.zdjecie4.childImageSharp.fluid}
          />
          <p>
            [3] Kliknij przycisk “dodaj nowy”, aby stworzyć atrybut dla
            pracowników. Możesz także edytować istniejące już atrybuty.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="tworzenie atrybutów"
            fluid={data.zdjecie5.childImageSharp.fluid}
          />
          <p>
            [4] Konfigurując atrubut pracowników, możesz go dostosować do swoich
            potrzeb. Zdecyduj, jak ma brzmieć jego nazwa, jaki ma mieć format
            (np. tekstowy lub liczbowy) czy też rozmiar.
          </p>

          <p>
            [5] Pamiętaj, aby zaznaczyć pole “Jest aktywne” oraz “Widoczny”.
          </p>

          <p>
            [6] Pamiętaj o zapisaniu wprowadzonych zmian. Klikając “zapisz i
            stwórz nowy”, możesz szybko przejść do tworzenia kolejnego atrybutu.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="tworzenie własnych atrybutów"
            fluid={data.zdjecie6.childImageSharp.fluid}
          />
          <p>
            [7] Przejdź do profilu pracownika, aby zobaczyć stworzone atrybuty.
          </p>

          <p>
            [8] Pojawią się one w tym miejscu w kolejności, jaką ustalisz
            podczas ich tworzenia.
          </p>
        </TabPanel>
      </Tabs>
    </HelpPage>
  );
};

export default ProfilPracownika;
